import React from "react";
import { useSelector } from "react-redux";
import { Redirect, Route } from "react-router-dom";
import {
   selectLoadingAuth,
   selectUser,
} from "../features/authentication/authenticationSlice";

export default function PrivateRoute({ component: Component, ...rest }) {
   const user = useSelector(selectUser);
   const authLoading = useSelector(selectLoadingAuth);
   const authorizedUids = [
      "arjun+dev@tumla.com",
      "arjun@tumla.com",
      "cloud_tester@tumla.com",
      "emrahdincadam@gmail.com",
      "joseph@corp.tumla.com",
      "manar.hussain@insead.edu",
      "manarh+isb@gmail.com",
      "rodrigo_neves97@outlook.com",
   ];

   return (
      <div>
         {authLoading === "succeeded" && (
            <Route
               {...rest}
               render={(props) =>
                  user && authorizedUids.includes(user.email) ? (
                     <Component {...props} />
                  ) : (
                     <Redirect
                        to={{
                           pathname: "/login",
                           state: { from: props.location },
                        }}
                     />
                  )
               }
            />
         )}
         {authLoading === "failed" && (
            <Route
               {...rest}
               render={(props) => (
                  <Redirect
                     to={{
                        pathname: "/login",
                        state: { from: props.location },
                     }}
                  />
               )}
            />
         )}
      </div>
   );
}
